<template>
    <div class="release">
        <div class="container top-container">
            <div class="page-title">
                <h1>{{ $t('page.release.title') }}</h1>
                <p>{{ $t('page.release.intro') }}</p>
            </div>
            <!--<nav class="note-nav">
                <router-link to="/release/editor"><img src="@/assets/logo/editor-logo.svg" alt=""> FairyGUI Editor</router-link>
                <router-link to="/release/sdk"><img src="@/assets/logo/sdk-logo.svg" alt=""> FairyGUI SDK</router-link>
                <router-link to="/release/unity-sdk"><img src="@/assets/logo/sdk-logo.svg" alt=""> FairyGUI Unity SDK</router-link>
            </nav>-->
        </div>
        <div class="history">
            <div v-for="item in history" :key="item.title" class="history-item">
                <div class="container">
                    <h2>{{ item.title }}</h2>
                    <div :class="[ 'content', foldState['h-' + item.title] ? 'tight' : '' ]" :ref="'h-' + item.title">
                        <vue-markdown @rendered="handleRendered('h-' + item.title)" :source="item.content"></vue-markdown>
                        <div v-if="foldState['h-' + item.title]" class="fold">
                            <a @click="unfold('h-' + item.title)" href="javascript:void(0)">{{ $t('page.release.expand') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: 'Release',
    data: () => {
        return {
            editorHistory: [],
            sdkHistory: [],
            unityHistory: [],
            foldState: {}
        }
    },
    computed: {
        history() {
            // switch (this.$route.path) {
            //     case '/release/editor':
            //         return this.editorHistory
            //     case '/release/sdk':
            //         return this.sdkHistory
            //     case '/release/unity-sdk':
            //         return this.unityHistory
            //     default:
            //         return this.editorHistory
            // }
            return this.editorHistory
        }
    },
    methods: {
        handleNote(note, list) {
            note.split('##').forEach(update => {
                if (update != '') {
                    update = update.trim()
                    const contentList = update.split('\n')
                    list.push({
                        title: contentList[0],
                        content: contentList.slice(1).join('\n')
                    })
                }
            })
        },
        handleRendered(ref) {
            this.$nextTick(() => {
                const el = this.$refs[ref]
                if (el[0].offsetHeight > 500) {
                    this.$set(this.foldState, ref, true)
                }
            })
        },
        unfold(ref) {
            this.$set(this.foldState, ref, false)
        }
    },
    mounted: async function () {
        const editorNote = (await import(`../data/release_note/${ this.$i18n.locale }/index.md`)).default
        const sdkNote = (await import(`../data/release_note/${ this.$i18n.locale }/sdk_release_notes.md`)).default
        const unitySdkNote = (await import(`../data/release_note/${ this.$i18n.locale }/unity_release_notes.md`)).default
        this.handleNote(editorNote, this.editorHistory)
        this.handleNote(sdkNote, this.sdkHistory)
        this.handleNote(unitySdkNote, this.unityHistory)
    },
    components: {
        VueMarkdown,
    }
}
</script>

<style lang="scss" scoped>
.release {
    .top-container {
        background: url(../assets/decoration/pop-white.svg) no-repeat;
        background-size: 100% auto;
        background-position: center top -60px;
        padding-bottom: 50px;
    }
    .note-nav {
        width: 100%;
        margin: 0 auto;
        max-width: 630px;
        display: flex;

        a {
            display: flex;
            margin-right: 10px;
            width: 1%;
            flex-grow: 1;
            align-items: center;
            padding: 11px 15px;
            border-radius: 6px;
            background: rgba(#FF971A, .1);
            color: var(--p);
            font-weight: bold;
            text-decoration: none;
            backdrop-filter: blur(10px);

            img {
                width: 21px;
                margin-right: 6px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background: rgba(#FF971A, .2);
            }
            &.router-link-active {
                background: var(--p);
                color: #fff;
                cursor: default;
            }
        }
        @media (max-width: 576px) {
            display: block;

            a {
                width: 100%;
                margin-bottom: 12px;
            }
        }
    }
}
.page-title {
    text-align: center;
    padding: 80px 0 35px;

    h1 {
        font-size: 48px;
        margin-bottom: 0;
    }
    p {
        font-size: 21px;
        margin-top: 10px;
    }
}
.history {
    .history-item {
        padding: 40px 0;

        .container {
            display: flex;
        }
        h2 {
            margin: 0;
            width: 250px;
            font-size: 36px;
        }
        .content {
            width: 1%;
            flex-grow: 1;
            position: relative;

            .fold {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 50px 0 20px;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
                text-align: center;
            }
            &.tight {
                max-height: 500px;
                overflow: hidden;
            }
        }
        &:nth-child(odd) {
            background: #f7f7f7;
            .fold {
                background: linear-gradient(180deg, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 50%);
            }
        }
        @media (max-width: 576px) {
            .container {
                flex-direction: column;

                h2 {
                    width: 100%;
                }
                .content {
                    width: 100%;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.history {
    .history-item {
        .content {
            line-height: 1.8;
            font-size: 16px;

            a {
                color: var(--p);
            }
            * {
                word-break: break-all;
            }
        }
    }
}
</style>